import { atom, selector, useRecoilValue } from "recoil";
import { API } from "./api";
import React, { useEffect } from "react";

const UP_API_KEY = "UP_API_KEY";

export const apiKeyAtom = atom({
  key: "upApiKey",
  default: localStorage.getItem(UP_API_KEY) || "",
});

export const apiSelector = selector({
  key: "upApiObject",
  get: ({ get }) => {
    return new API(get(apiKeyAtom));
  },
});

export const refreshAccountsAtom = atom({
  key: "refresh",
  default: Date.now(),
});

export const accountsSelector = selector({
  key: "accounts",
  get: async ({ get }) => {
    get(refreshAccountsAtom);
    const api = get(apiSelector);
    try {
      return (await api.listAccounts()).data;
    } catch (e) {
      console.error("Failed to fetch accounts list.");
      return null;
    }
  },
});

export const modalContentAtom = atom<React.ReactNode>({
  key: "modalContent",
  default: null,
});

export const StateObserver = () => {
  const apiKey = useRecoilValue(apiKeyAtom);

  useEffect(() => {
    localStorage.setItem(UP_API_KEY, apiKey);
  }, [apiKey]);

  return null;
};
