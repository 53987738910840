import React from "react";
import { Account } from "../../data/types";
import { Link } from "react-router-dom";

import componentStyles from "../../styles/component.module.scss";

interface Props {
  account: Account;
}

const AccountComponent = ({ account: { id, attributes } }: Props) => {
  return (
    <div className={componentStyles.card}>
      <Link to={`/accounts/${id}`}>{attributes.displayName}</Link>
      <span>{attributes.accountType}</span>
      <span>
        {attributes.balance.value} {attributes.balance.currencyCode}
      </span>
    </div>
  );
};

export default AccountComponent;
