import React from "react";
import styles from "../../styles/modal.module.scss";
import { IoIosCloseCircle } from "react-icons/io";
import { useRecoilState } from "recoil";
import { modalContentAtom } from "../../data/state";

const ModalComponent = () => {
  const [content, setContent] = useRecoilState(modalContentAtom);

  if (!content) return null;

  return (
    <div className={styles.wrapper} onClick={() => setContent(null)}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <span className={styles.close} onClick={() => setContent(null)}>
          <IoIosCloseCircle />
        </span>
        {content}
      </div>
    </div>
  );
};

export default ModalComponent;
