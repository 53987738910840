import React, { Suspense } from "react";
import { RecoilRoot } from "recoil";
import AccountsList from "./pages/AccountsList";
import { StateObserver } from "./data/state";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import AccountDetail from "./pages/AccountDetail";
import ModalComponent from "./components/Modal";
import FooterComponent from "./components/Footer";

import "./styles/global.scss";
import layoutStyles from "./styles/layout.module.scss";
import NavbarComponent from "./components/Navbar";

function App() {
  return (
    <Suspense fallback={<h1>lol</h1>}>
      <RecoilRoot>
        <StateObserver />
        <ModalComponent />
        <div className={layoutStyles.main}>
          <NavbarComponent />
          <div className={layoutStyles.content}>
            <BrowserRouter>
              <Switch>
                <Route path="/accounts/:id">
                  <AccountDetail />
                </Route>
                <Route path="/accounts">
                  <AccountsList />
                </Route>
                <Route exact path="/">
                  <Redirect to="/accounts" />
                </Route>
              </Switch>
            </BrowserRouter>
          </div>
          <FooterComponent />
        </div>
      </RecoilRoot>
    </Suspense>
  );
}

export default App;
