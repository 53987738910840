import React from "react";
import { useRecoilValue } from "recoil";
import { accountsSelector } from "../../data/state";
import AccountComponent from "../../components/Account";

const AccountsList = () => {
  const accounts = useRecoilValue(accountsSelector);

  return (
    <div>
      {accounts === null && <p>Failed to load accounts list</p>}
      {!accounts && accounts !== null && <p>Loading accounts...</p>}
      {accounts &&
        accounts.map((account) => (
          <AccountComponent key={account.id} account={account} />
        ))}
    </div>
  );
};

export default AccountsList;
