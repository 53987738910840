import React, { useEffect, useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { apiSelector } from "../../data/state";
import { Transaction, AccountAttributes } from "../../data/types";
import TransactionComponent from "../../components/Transaction";

const AccountDetail = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();
  const api = useRecoilValue(apiSelector);
  const [transactions, setTransactions] = useState<Transaction[]>();
  const [account, setAccount] = useState<AccountAttributes | null>(null);

  useEffect(() => {
    const load = async () => {
      const { data: account } = await api.getAccount(id);
      const { data: transactions } = await api.getTransactions(id);
      if (!account || !transactions) return;

      setAccount(account.attributes);
      setTransactions(transactions);
    };
    load();
  }, [api, id]);

  return (
    <div>
      <div>
        <Link to="/accounts">Back to accounts</Link>
        {account ? (
          <>
            <span>{account.displayName}</span>
            <span>{account.accountType}</span>
            <span>
              {account.balance.value} {account.balance.currencyCode}
            </span>
          </>
        ) : (
          <>
            <span>Loading account...</span>
          </>
        )}
      </div>
      {transactions?.map((transaction) => (
        <TransactionComponent key={transaction.id} transaction={transaction} />
      ))}
    </div>
  );
};

export default AccountDetail;
