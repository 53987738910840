import React from "react";
import { IoMdHelp, IoMdSettings } from "react-icons/io";

import styles from "../../styles/layout.module.scss";
import { useSetRecoilState } from "recoil";
import { modalContentAtom } from "../../data/state";
import HelpModal from "../Help";
import SettingsModal from "../Settings";

const NavbarComponent = () => {
  const setModalContent = useSetRecoilState(modalContentAtom);

  return (
    <nav className={styles.navbar}>
      <ul className={styles.left}>
        <li className={styles.navbarItem}>Uppity</li>
      </ul>
      <ul>
        <li
          className={styles.navbarItem}
          onClick={() => setModalContent(<SettingsModal />)}
        >
          <span>
            <IoMdSettings />
          </span>
        </li>
        <li
          className={styles.navbarItem}
          onClick={() => setModalContent(<HelpModal />)}
        >
          <span>
            <IoMdHelp />
          </span>
        </li>
      </ul>
    </nav>
  );
};

export default NavbarComponent;
