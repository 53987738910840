import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { apiKeyAtom } from "../../data/state";

const SettingsModal = () => {
  const [apiKey, setApiKey] = useRecoilState(apiKeyAtom);
  const [keyInput, setKeyInput] = useState(apiKey);
  return (
    <div>
      <h1>Settings</h1>
      <label>
        API Key (You can get one{" "}
        <a href="https://api.up.com.au/getting_started">here!</a>)
      </label>
      <br />
      <input
        value={keyInput}
        onChange={(e) => setKeyInput(e.target.value)}
        onBlur={(e) => setApiKey(keyInput)}
      />
    </div>
  );
};

export default SettingsModal;
