import React from "react";
import { Transaction } from "../../data/types";

import componentStyles from "../../styles/component.module.scss";

interface Props {
  transaction: Transaction;
}

const TransactionComponent = ({
  transaction: {
    attributes: { rawText, description, amount, foreignAmount },
  },
}: Props) => {
  return (
    <div className={componentStyles.card}>
      <span>{description}</span>
      <span>
        {amount.value} {amount.currencyCode}
      </span>
      {foreignAmount && (
        <span>
          {foreignAmount.value} {foreignAmount.currencyCode}
        </span>
      )}
    </div>
  );
};

export default TransactionComponent;
