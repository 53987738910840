import React from "react";

const HelpModal = () => {
  return (
    <div>
      <h1>Help</h1>
      <h2>What is this?</h2>
      <p>
        An quick webapp that I wrote over a weekend to view your UP account on a
        desktop.
      </p>

      <h2>Is it safe?</h2>
      <p>
        Your access token is only stored within this browser, and is only
        transferred directly to UP for the purposes of getting your data. I do
        not have access to your tokens at any point. Additionally, the source
        code is on GitHub if you ever wanted to have a look.
      </p>
    </div>
  );
};

export default HelpModal;
