import wretch, { Wretcher } from "wretch";
import { Response, Account, Transaction } from "./types";
const API_BASE = "https://api.up.com.au/api/v1/";

export class API {
  private endpoint: Wretcher;

  constructor(token: string) {
    this.endpoint = wretch(API_BASE).headers({
      authorization: `Bearer ${token}`,
    });
  }

  public async listAccounts() {
    return this.endpoint.url("/accounts").get().json() as Promise<
      Response<Account[]>
    >;
  }

  public async getAccount(id: string) {
    return this.endpoint.url(`/accounts/${id}`).get().json() as Promise<
      Response<Account>
    >;
  }

  public async getTransactions(id: string, size?: number, page?: string) {
    return this.endpoint
      .url(`/accounts/${id}/transactions`)
      .query({ "page[size]": size || 50 })
      .get()
      .json() as Promise<Response<Transaction[]>>;
  }
}
