import React from "react";

import styles from "../../styles/layout.module.scss";

const FooterComponent = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <p>
          Not affiliated with or endorsed by <a href="https://up.com.au">UP</a>
        </p>
        <p>
          Source: <a href="https://github.com/lecafard/uppity">GitHub</a>
        </p>
      </div>
    </footer>
  );
};

export default FooterComponent;
